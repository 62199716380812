import React from 'react';
import Logo from '../images/LogoLight.png';
import {navigate} from 'gatsby';

export default function NotFound() {
  React.useEffect(() => {
    navigate('/');
  }, []);
  return (
    <div style={{backgroundColor: 'lightgrey', width: '100%', height: '100%'}}>
      <img src={Logo} style={{height: 50}} />
    </div>
  );
}
